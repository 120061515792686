import { gql } from '@apollo/client'
import { UserData, MediaData } from '@cms/core/graphql/fragments'

export const CREATE_OR_UPDATE_USER = gql`
    mutation CreateOrUpdateUser(
        $name: String!
        $email: String!
        $department: String
        $jobTitle: String
        $password: String
        $role: String
        $profile: ObjectID
        $watchPlan: ObjectID
    ) {
        createOrUpdateUser(
            input: {
                name: $name
                email: $email
                department: $department
                jobTitle: $jobTitle
                password: $password
                role: $role
                profile: $profile
                watchPlan: $watchPlan
            }
        ) {
            ...UserData
        }
    }

    ${UserData}
`

export const CREATE_USER = gql`
    mutation CreateUser(
        $name: String!
        $email: String!
        $department: String
        $jobTitle: String
        $password: String
        $role: String
        $profile: ObjectID
    ) {
        createUser(
            input: {
                name: $name
                email: $email
                department: $department
                jobTitle: $jobTitle
                password: $password
                role: $role
                profile: $profile
            }
        ) {
            ...UserData
        }
    }

    ${UserData}
`

export const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: ObjectID!
        $name: String
        $email: String
        $department: String
        $jobTitle: String
        $password: String
        $avatar: MediaInput
        $role: String
        $profile: ObjectID
        $watchPlan: ObjectID
    ) {
        updateUser(
            id: $id
            input: {
                name: $name
                email: $email
                department: $department
                jobTitle: $jobTitle
                password: $password
                avatar: $avatar
                role: $role
                profile: $profile
                watchPlan: $watchPlan
            }
        ) {
            ...UserData
        }
    }

    ${UserData}
`

export const DELETE_USER = gql`
    mutation ($id: ObjectID!) {
        updateUser(id: $id, input: { role: "lead" }) {
            id
            role
        }
    }
`
export const BLOCK_TALK_CHAT_USER = gql`
    mutation blockTalkChatUer($id: ObjectID!, $blockDate: DateTime) {
        blockTalkChat(id: $id, input: { blockDate: $blockDate }) {
            id
            blockDate
        }
    }
`

export const BAN_USER = gql`
    mutation BanUser($id: ObjectID!) {
        banUser(id: $id) {
            id
            name
            banned
        }
    }
`

export const UNDO_BAN_USER = gql`
    mutation UndoBanUser($id: ObjectID!) {
        undoBanUser(id: $id) {
            id
            name
            banned
        }
    }
`

export const UPLOAD_AVATAR = gql`
    mutation UploadAvatar($file: Upload!) {
        uploadAvatar(file: $file) {
            ...MediaData
        }
    }

    ${MediaData}
`
