import React, { useState } from 'react'
import { Form, Radio, Select, Switch, Tag } from 'antd'

import { ButtonConfig, ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_SPEAKER_TAGS } from '@cms/events/graphql/queries/speaker'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import {
    AlignLeftOutlined,
    AlignCenterOutlined,
    AlignRightOutlined
} from '@ant-design/icons'
const Speakers = (props) => {
    const { eventId } = useParams()
    const { data: TagsData } = useQuery(GET_SPEAKER_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Regras de exibição</Title>
                <Form.Item
                    name={[props.name, 'configs', 'filters', 'featured']}
                    valuePropName="checked"
                    initialValue={false}
                    label="Exibir palestrantes Destaque"
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                    />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Exibir apenas palestrantes com as tags:"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.speakerTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Alinhamento</Title>
                <Form.Item
                    name={[props.name, 'configs', 'alignment']}
                    label="Texto"
                >
                    <Radio.Group
                        initialValue={'start'}
                        style={{ marginBottom: 8 }}
                    >
                        <Radio.Button
                            value="start"
                            style={{
                                borderRight: '1px solid rgb(255 255 255 / 10%)'
                            }}
                        >
                            <AlignLeftOutlined />
                        </Radio.Button>
                        <Radio.Button value="center">
                            <AlignCenterOutlined />
                        </Radio.Button>
                        <Radio.Button value="end">
                            <AlignRightOutlined />
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Title level={4}>Box</Title>
                <Form.Item
                    name={[props.name, 'configs', 'cards', 'text', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'cards', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'cards', 'icon', 'color']}
                    label="Cor do icone das redes sociais"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Popup do palestrante</Title>
                <Form.Item
                    name={[props.name, 'configs', 'modal', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'modal', 'text', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'modal', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'modal', 'icon', 'color']}
                    label="Cor do icone das redes sociais"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>

                <ButtonConfig enableActions allowVisibilityControl {...props} />
            </Wrapper>
        </>
    )
}

export default Speakers
