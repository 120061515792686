import React from 'react'
import { Form, Input, Divider, Select, Switch } from 'antd'

import { ButtonConfig, ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import { usePageBuilderContext } from '../../context/context'

const Viral2 = (props) => {
    const { event } = usePageBuilderContext()
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'headline', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>

            {!event.viralCampaign && (
                <>
                    <Wrapper>
                        <Title level={4}>Subtitulo</Title>
                        <Form.Item
                            name={[props.name, 'configs', 'subtitle', 'text']}
                            label="Texto"
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'subtitle',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>

                    <ButtonConfig
                        fieldPath={[props.name, 'configs', 'button']}
                        enableText
                        allowVisibilityControl
                        enableActions
                        {...props}
                    />
                </>
            )}

            {event.viralCampaign && (
                <>
                    <Wrapper>
                        <Title level={4}>Box</Title>
                        <Form.Item
                            name={[props.name, 'configs', 'box', 'bgColor']}
                            label="Cor de fundo"
                        >
                            <ColorsBadges />
                        </Form.Item>
                        <Title level={4}>Viral</Title>
                        <Divider orientation="left">Texto</Divider>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'viralWidget',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>

                        <ButtonConfig
                            title="buttons"
                            fieldPath={[
                                props.name,
                                'configs',
                                'box',
                                'viralWidget',
                                'buttons'
                            ]}
                            inner
                            {...props}
                        />
                    </Wrapper>
                    <Wrapper>
                        <Title level={4}>Botão topo</Title>
                        <ButtonConfig
                            inner
                            fieldPath={[
                                props.name,
                                'configs',
                                'box',
                                'telegram',
                                'button'
                            ]}
                            allowVisibilityControl
                            defaultAction=""
                            enableActions
                            {...props}
                        />
                    </Wrapper>
                    <Wrapper>
                        <Title level={4}>Exibição de imagens</Title>
                        <Form.Item
                            name={[props.name, 'configs', 'topRewardImages']}
                            label="Premiação TOP"
                            valuePropName="checked"
                            initialValue={true}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            name={[props.name, 'configs', 'rewardImages']}
                            label="Premiação normal"
                            valuePropName="checked"
                            initialValue={false}
                        >
                            <Switch />
                        </Form.Item>
                    </Wrapper>
                    <Wrapper>
                        <Title level={4}>Recompensas TOP</Title>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'toprewards',
                                'bgColor'
                            ]}
                            label="Cor do fundo"
                        >
                            <ColorsBadges />
                        </Form.Item>
                        <Divider orientation="left">Indicações</Divider>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'toprewards',
                                'indications',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                        <Divider orientation="left">Recompensas</Divider>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'toprewards',
                                'reward',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>

                    <Wrapper>
                        <Title level={4}>Recompensas</Title>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'rewards',
                                'bgColor'
                            ]}
                            label="Cor do fundo"
                        >
                            <ColorsBadges />
                        </Form.Item>
                        <Divider orientation="left">Indicações</Divider>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'rewards',
                                'indications',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                        <Divider orientation="left">Recompensas</Divider>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'box',
                                'rewards',
                                'reward',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>
                    <Wrapper>
                        <Title level={4}>Rodapé</Title>
                        <Form.Item
                            name={[props.name, 'configs', 'footer', 'text']}
                            label="Texto"
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'footer',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>
                </>
            )}
        </>
    )
}

export default Viral2
