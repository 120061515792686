import React, { useEffect } from 'react'
import { Form, Select, Row, Tag, Input, message, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Uploader, PanelFooter } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'
import { useHistory, useParams } from 'react-router-dom'
import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'
import { useMutation } from '@apollo/client'

const PageConfig = (props) => {
    const [updateSite] = useMutation(UPDATE_SITE)
    const [form] = Form.useForm()
    const { site, setSite, config } = usePageBuilderContext()
    const history = useHistory()
    const { page } = props
    const { eventId } = useParams()
    const baseUrl = `/evento/${eventId}/paginas/page`

    const deletePage = async () => {
        const { id } = config
        delete site.pages[page]
        setSite(site)
        await updateSite({
            variables: {
                id: id,
                draft: site
            }
        }).finally(() => {
            history.push(baseUrl + '/home')
            message.success(`Página deletada com sucesso`)
        })
    }

    const hanleOnKeyPress = (e) => {
        const regex = new RegExp(/^[A-Za-z0-9_-]+(?:-[A-Za-z0-9_-]+)*$/)
        const str = String.fromCharCode(!e.charCode ? e.which : e.charCode)
        if (regex.test(str)) {
            return true
        }

        e.preventDefault()
        return false
    }

    useEffect(() => {
        form.setFieldsValue({
            name: site.pages[page].name,
            slug: site.pages[page].slug
        })
    }, [site, page, form])

    if (!site) return null

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <ColForm lg={24} md={12} sm={24}>
                <Form name={`pages.${page}`} form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Nome da página"
                        rules={[
                            {
                                required: true,
                                message: 'Nome é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="slug"
                        label="Slug"
                        rules={[
                            {
                                required: true,
                                message: 'Slug é obrigatório!'
                            }
                        ]}
                    >
                        <Input onKeyPress={hanleOnKeyPress} />
                    </Form.Item>

                    {site.pages[page].custom && (
                        <Popconfirm
                            title="Você realmente quer remover esta página?"
                            onConfirm={() => {
                                deletePage()
                            }}
                            onCancel={(e) => {}}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <a href="#">
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    )}

                    <PanelFooter customPage />
                </Form>
            </ColForm>
        </Row>
    )
}

export default PageConfig
