import React, { useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import { useQuery } from '@apollo/client'

import SelectSearch from '@cms/core/components/SelectSearch'
import Loader from '@cms/core/components/Loader'

import BodyValues from './BodyValues'
import { ConversionIntegrationContainer } from './styles'
import { GET_ZOHO_INTEGRATION_DETAILS } from '@cms/core/graphql/queries/integrations'

const variables = {
    'Contact Email': '{{email}}',
    'First Name': '{{firstName}}',
    'Last Name': '{{lastName}}'
}

const ZohoStep2 = ({ form, integrationId }) => {
    const { loading, error, data } = useQuery(GET_ZOHO_INTEGRATION_DETAILS, {
        variables: {
            integrationId,
            // TODO: Dinamic pagination
            limit: 20,
            offset: 0
        }
    })

    useEffect(() => {
        const fetchAll = async () => {
            const defaultFieldsMappings = form.getFieldValue(['config', 'body'])

            const fieldsMapping = [
                defaultFieldsMappings ? [...defaultFieldsMappings] : [],
                ...data.zohoCampaignsIntegrationContactFields
                    .filter((contact) => !contact.optional)
                    .map((contact) => ({
                        name: contact.name,
                        value: variables[contact.name] || ''
                    }))
            ].flat()

            const fieldsMappingMap = [
                ...new Map(
                    fieldsMapping.map((item) => [item.name, item])
                ).values()
            ]

            form.setFieldValue('config', {
                listKey: form.getFieldValue(['config', 'listKey']),
                body: fieldsMappingMap
            })
        }
        integrationId && data && fetchAll()
    }, [integrationId, data])

    if (loading) return <Loader fixed={false} padding="10px" />

    if (error) return <></>

    return (
        <ConversionIntegrationContainer>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={['config', 'listKey']}
                        label="Mailing List"
                        rules={[
                            { required: true, message: 'Campo obrigatório' }
                        ]}
                    >
                        <SelectSearch
                            mode="single"
                            options={data?.zohoCampaignsIntegrationMailingLists?.items.map(
                                (m) => ({
                                    label: m.name,
                                    value: m.id
                                })
                            )}
                        />
                    </Form.Item>
                </Col>
                <BodyValues
                    formName={['config', 'body']}
                    nameOptions={data?.zohoCampaignsIntegrationContactFields.map(
                        (c) => ({
                            label: c.name,
                            value: c.name
                        })
                    )}
                    removeActionsIn={data?.zohoCampaignsIntegrationContactFields
                        ?.filter((contact) => !contact.optional)
                        .map((contact, index) => index)}
                />
            </Row>
        </ConversionIntegrationContainer>
    )
}

export default ZohoStep2

