import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_INTEGRATIONS = gql`
    query GetIntegrations(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $category: Filter
        $appType: AppType = EVENTS
    ) {
        integrations(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, category: $category, appType: $appType }
        ) {
            edges {
                node {
                    ...IntegrationData
                }
            }
        }
    }
    ${fragments.IntegrationData}
`

export const GET_INTEGRATION = gql`
    query GetIntegration($id: ObjectID!) {
        integration(id: $id) {
            ...IntegrationData
        }
    }

    ${fragments.IntegrationData}
`

export const GET_ZOHO_INTEGRATION_DETAILS = gql`
    query ZohoCampaignsIntegrationDetails(
        $integrationId: ObjectID!
        $limit: Int!
        $offset: Int!
    ) {
        zohoCampaignsIntegrationMailingLists(
            integrationId: $integrationId
            limit: $limit
            offset: $offset
        ) {
            items {
                id
                name
            }
            metadata {
                totalCount
            }
        }
        zohoCampaignsIntegrationContactFields(integrationId: $integrationId) {
            name
            type
            optional
        }
    }
`

export const GET_ZOHO_CRM_INTEGRATION_DETAILS = gql`
    query ZohoCampaignsIntegrationDetails($integrationId: ObjectID!) {
        zohoCRMIntegrationCampaigns(integrationId: $integrationId) {
            id
            name
        }
        zohoCRMIntegrationFields(integrationId: $integrationId) {
            id
            name
            custom
            optional
        }
    }
`

export const GET_ZOHO_AUTH_URL = gql`
    query zohoAuthUrl($integrationId: ObjectID!, $clientRedirectUri: String!) {
        zohoAuthUrl(
            integrationId: $integrationId
            clientRedirectUri: $clientRedirectUri
        )
    }
`

